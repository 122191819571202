import Link from "components/Link";
import Image from "components/Image";
import {formatDateTime} from "../../utils/formatDateTime";
import {handleHit} from "../../utils/handleHit";

const Card15Podcast = ({className = "h-full", post}) => {
    const {title, href, featuredImage, date, kod} = post;

    return (
        <div
            onClick={() => handleHit(kod)}
            className={`nc-Card15Podcast relative flex group items-center p-3 rounded-3xl border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 ${className}`}
        >
            <div className="w-1/4 flex-shrink-0">
                <Link
                    href={href}
                    className="block h-0 aspect-w-1 aspect-h-1 relative rounded-full overflow-hidden shadow-lg"
                >
                    <Image
                        className="object-cover w-full h-full"
                        src={featuredImage}
                        fill
                        alt={title}
                        sizes="100px"
                    />
                </Link>
            </div>

            <div className="flex flex-col flex-grow ml-4">
                <h2 className={`nc-card-title block  text-sm sm:text-md`}>
                    <Link
                        href={href}
                        title={title}
                    >
                        {title}
                    </Link>
                </h2>
                <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 ">
                {formatDateTime(date)}
                </span>
            </div>
        </div>
    );
};

export default Card15Podcast;
