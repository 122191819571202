import { useParams } from "react-router-dom";
import Image from "../../components/Image";
import { useEffect, useRef, useState } from "react";
import PostCardLikeAction from "../../components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "../../components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import Tag from "../../components/Tag/Tag";
import SingleCommentForm from "../article/SingleCommentForm";
import SingleCommentLists from "../article/SingleCommentLists";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { getCookie } from "../../hooks/cookieHooks";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import Page404 from "../not-found";
import Schema from "../../components/Schema";
import AuthorCard from "../../components/AuthorCard";
import AuthorHeader from "./AuthorHeader";
import { FaSpinner } from "react-icons/fa6";
// import AdSense from "react-adsense";
import SectionAds from "components/Sections/SectionAds";
import Card3Small from "components/Card3Small/Card3Small";

export default function AuthorDetails() {
    const params = useParams();
    const endedAnchorRef = useRef(null);
    const contentRef = useRef(null);
    const progressRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);
    const [details, setDetails] = useState([]);
    const [comments, setComments] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(10);
    const [others, setOthers] = useState([]);
    const [author, setAuthor] = useState([]);
    const [totalArticles, setTotalArticles] = useState(0);
    const [continueButton, setContinueButton] = useState("Devamını Gör");
    const [isShowScrollToTop, setIsShowScrollToTop] = useState(false);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
        threshold: 0,
        root: null,
        rootMargin: "0%",
        freezeOnceVisible: false,
    });
    const totalComment = comments.length;

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await fetchAPI('getAuthorNews', createFormData, sendRequest, start, end, params.slug);
                const detailsData = data.details;
                const othersData = data.otherNews;
                const { author } = data
                setTotalArticles(data.totalArticles[0].totalArticles);

                othersData.forEach(item => {
                    item.avatar = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/yazarlar/detay/' + item.href;
                    item.displayName = author[0].displayName
                    item.lastArticle = item.title
                });

                author.forEach(item => {
                    // item.lastArticle = new DOMParser().parseFromString(item.lastArticle, 'text/html').body.textContent
                    item.avatar = "https://www.avrupaninsesi.com/" + item.avatar;
                    item.avatar = item.avatar.replaceAll(".webp", ".jpg")
                });

                setOthers(othersData);
                setAuthor(author[0]);
                setComments(data.comments);

                const regex = /\/isDosyalar/g;

                detailsData.forEach((item) => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/galeri_" + item.url;
                    if (!item.detay.includes('https://avrupaninsesi.com/isDosyalar/')) {
                        item.detay = item.detay.replace(regex, "https://www.avrupaninsesi.com/isDosyalar");
                    }

                    if (item.detay.includes('https://www.youtube.com/watch?v=')) {
                        const youtubeUrl = item.detay.match(/(https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_-]+))\*\*\*/);
                        if (youtubeUrl && youtubeUrl.length >= 3) {
                            const videoId = youtubeUrl[2];
                            const embedCode = `<iframe class="w-full h-96 object-cover" src="https://www.youtube.com/embed/${videoId}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                            item.detay = item.detay.replace(youtubeUrl[1], embedCode).replace("***", '');
                        }
                    }
                    if (item.etiketler) item.etiketler = item.etiketler.replace(/,\s*$/, '');
                });

                setDetails(detailsData[0]);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
        setContinueButton("Devamını Gör")
    }, [createFormData, sendRequest, params.slug, trigger, start, end]);

    useEffect(() => {
        const handleProgressIndicator = () => {
            const entryContent = contentRef.current;
            const progressBarContent = progressRef.current;

            if (!entryContent || !progressBarContent) {
                return;
            }

            const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            let scrolled = (winScroll / totalEntryH) * 100;

            progressBarContent.innerText = scrolled.toFixed(0) + "%";

            if (scrolled >= 100) {
                setIsShowScrollToTop(true);
            } else {
                setIsShowScrollToTop(false);
            }
        };

        const handleProgressIndicatorHeadeEvent = () => {
            window?.requestAnimationFrame(handleProgressIndicator);
        };
        handleProgressIndicator();
        window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
        return () => {
            window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
        };
    }, []);

    const commentSubmit = async (values) => {
        const token = getCookie('t');
        const formData = new FormData();
        formData.append("token", token);
        formData.append("action", "avrupaninsesi");
        formData.append("page", "postComment");

        formData.append("name_surname", values.name_surname);
        formData.append("email", values.email);
        formData.append("comment", values.comment);
        formData.append("code", details.kod);

        const { error, message } = await axios.post("https://avrupaninsesi.com/api/index.php", formData)
            .then(res => res.data)
            .catch(err => console.error(err))

        if (error) {
            const messages = message.split("\n");
            messages.forEach(message => {
                if (message !== "") toast.error(message);
            })
        } else {
            toast.success(message)
            setTrigger(!trigger)
        }
    }

    const handlePageChange = () => {
        setContinueButton(<FaSpinner className="animate-spin" />)
        setStart(end);
        setEnd(end + 10);
    };

    const showLikeAndCommentSticky =
        !endedAnchorEntry?.intersectionRatio &&
        (endedAnchorEntry?.boundingClientRect.top || 0) >= 0;

    let etiketler = [];
    if (details.etiketler) {
        etiketler = details.etiketler.split(',')
            .map(item => item.trim())
            .filter(item => item !== '') || [];
    }

    const pageCard = () => {
        return (
            <div
                className={`sticky mt-8 bottom-8 !z-50 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
                    }`}
            >
                <div
                    className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
                    <PostCardLikeAction likeCount={details.hit} className="px-3 h-9 text-xs" />
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
                    <PostCardCommentBtn
                        commentCount={totalComment}
                        isATagOnSingle
                        className={` flex px-3 h-9 text-xs`}
                    />
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

                    <button
                        className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
                            }`}
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        <ArrowUpIcon className="w-4 h-4" />
                    </button>

                    <button
                        ref={progressRef}
                        className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
                            }`}
                        title="Go to top"
                    >
                        %
                    </button>
                </div>
            </div>
        )
    }

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                {etiketler.map((item, key) => (
                    <Tag hideCount key={key} tag={item} className="mr-2 mb-2" />
                ))}
            </div>
        )
    }

    const renderContent = () => {
        return (
            <>
                <Toaster />
                <SectionAds />
                <div className="relative">
                    <div className="nc-SingleContent space-y-10">
                        <div
                            id="single-entry-content"
                            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                            ref={contentRef}
                        >
                            <div className="my-5">
                                <blockquote
                                    className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                                    <svg className="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                        <path
                                            d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                                    </svg>
                                    <p className="text-xl italic font-medium leading-relaxed text-gray-900 dark:text-white">{details.spot}</p>
                                </blockquote>
                            </div>
                            <FroalaEditorView
                                className="!z-10"
                                model={details.detay}
                            />

                            {others && (
                                <>
                                    <div
                                        className="mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
                                    {others.map((post, index) => {
                                        return (
                                            <Card3Small key={index} post={post} />
                                        )
                                    })}
                                    <div className="flex justify-end text-sm mt-3">
                                        {others.length} adet kayıt listelendi
                                    </div>
                                    <button
                                        type="button"
                                        disabled={others.length >= totalArticles - 10}
                                        className="flex justify-center items-center mt-12 border w-64 mx-auto p-3 rounded-full bg-[#2b3a7b] text-white cursor-pointer disabled:bg-gray-600 disabled:text-gray-400 disabled:cursor-not-allowed"
                                        onClick={handlePageChange}>
                                        {(others.length >= totalArticles - 10) ? "Tümü yüklendi" : continueButton}
                                    </button>
                                </>
                            )}
                        </div>
                        {renderTags()}



                        <div
                            className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>

                        <div id="comments" className="scroll-mt-20 max-w-screen-md mx-auto pt-5">
                            <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                                Yorumlarınızla Katkı Sağlayın
                            </h3>
                            <SingleCommentForm onClickSubmit={commentSubmit} />
                        </div>

                        <div className="max-w-screen-md mx-auto">
                            <SingleCommentLists totalComment={totalComment} comments={comments} />
                            <div ref={endedAnchorRef}></div>
                        </div>
                    </div>
                    {pageCard()}
                </div>
            </>
        )
    }

    return (
        <>
            {loading && <Loading />}
            {!details && (
                <Page404 />
            )}
            {!loading && details && (
                <>
                    <Schema content={details} />

                    <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
                        <div className="dark container relative z-10">
                            <div className="max-w-screen-md">
                                <AuthorHeader details={details} totalComment={totalComment} author={author} hiddenDesc />
                            </div>
                        </div>
                        <div
                            className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
                            <div
                                className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
                            <Image
                                className="md:block w-full h-full object-cover hidden"
                                src="/author-details.jpg"
                                alt=""
                                width={1635}
                                height={774}
                                sizes="(max-width: 1024px) 100vw, 1240px"
                            />
                        </div>
                    </header>
                    <AuthorCard isLink={false} content={author} />
                    <div className="container mt-10 max-w-7xl relative">
                        {renderContent()}
                    </div>
                </>
            )}
        </>
    )
}