import axios from "axios";
import { getCookie } from "../hooks/cookieHooks";

export async function handleHit(code) {
    const formData = new FormData();
    const token = getCookie("t");

    formData.append("action", "avrupaninsesi");
    formData.append("page", "postHit");
    formData.append("code", code);
    formData.append("token", token);
    await axios.post("https://avrupaninsesi.com/api/index.php", formData);
}