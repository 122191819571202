import React, {FC} from "react";
import {Form, Formik, FormikValues} from "formik";
import Input from '../../components/Formik/Input';
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import Textarea from "../../components/Formik/Textarea";

export interface SingleCommentFormProps {
    className?: string;
    onClickSubmit?: (values: FormikValues) => void;
    onClickCancel?: () => void;
    textareaRef?: React.MutableRefObject<null>;
    defaultValue?: string;
    rows?: number;
}

const SingleCommentForm: FC<SingleCommentFormProps> = ({className = "mt-5", onClickSubmit}) => {
    return (
        <div className={`nc-SingleCommentForm ${className}`}>

            <Formik
                initialValues={{
                    name_surname: '',
                    email: '',
                    comment: ''
                }}
                onSubmit={async values => {
                    if (onClickSubmit) {
                        onClickSubmit(values)
                    }
                }}
            >
                {() => (
                    <Form>
                        <div className="grid gap-y-4">
                            <Input label="Adı Soyadı" placeholder="İsim soyisim giriniz" required name="name_surname"
                                   value={undefined} className={undefined}
                                   underlineStyle={undefined}/>

                            <Input label="E-posta" name="email" placeholder="Mail adresinizi giriniz" required
                                   value={undefined} className={undefined}
                                   underlineStyle={undefined}/>

                            <Textarea rows={4} label="Mesaj" name="comment" required className={undefined}></Textarea>

                            <div className="mt-2 space-x-3">
                                <ButtonPrimary
                                    className="w-full" type="submit">
                                    Gönder
                                </ButtonPrimary>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SingleCommentForm;
