import React, {FC} from "react";
import CommentCard from "../../components/CommentCard/CommentCard";

export interface SingleCommentListsProps {
    comments: [],
    totalComment: number
}

const SingleCommentLists: FC<SingleCommentListsProps> = ({comments}) => {
    return (
        <ul className="nc-SingleCommentLists space-y-5">
            {comments.map(comment => (
                <CommentCard comment={comment}/>
            ))}

            {/*{totalComment > 10 && (*/}
            {/*    <ButtonPrimary className="dark:bg-primary-700 w-full">*/}
            {/*        Tümünü Gör (+ {totalComment} Haber)*/}
            {/*    </ButtonPrimary>*/}
            {/*)}*/}
        </ul>
    );
};

export default SingleCommentLists;
