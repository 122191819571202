import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import HTMLFlipBook from "react-pageflip";
import SectionAds from "components/Sections/SectionAds";
import GoBackButton from "components/GoBackButton";

export default function PublicationsDetails() {
    const params = useParams();
    const contentRef = useRef(null);
    const progressRef = useRef(null);
    const [width, setWidth] = useState(800);
    const [height, setHeight] = useState(1000);

    const [loading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    useEffect(() => {
        async function fetchData() {
            try {
                const data = await fetchAPI('newspapersDetails', createFormData, sendRequest, null, null, params.slug);
                const { gallery } = data;

                gallery.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/" + item.url;
                    item.featuredImage = item.featuredImage.replace(/\.webp/g, ".jpg");
                });

                setGallery(gallery);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, params.slug]);

    useEffect(() => {
        const handleProgressIndicator = () => {
            const entryContent = contentRef.current;
            const progressBarContent = progressRef.current;

            if (!entryContent || !progressBarContent) {
                return;
            }

            const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            let scrolled = (winScroll / totalEntryH) * 100;

            progressBarContent.innerText = scrolled.toFixed(0) + "%";

        };

        const handleProgressIndicatorHeadeEvent = () => {
            window?.requestAnimationFrame(handleProgressIndicator);
        };
        handleProgressIndicator();
        window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
        return () => {
            window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
        };
    }, []);


    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <GoBackButton background={false} />


                    <div className="grid grid-rows-1 my-3 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-12">
                        <SectionAds />
                        <HTMLFlipBook
                            width={width}
                            height={height}
                            autoSize={true}
                            className="col-span-12 mx-auto"
                        >
                            {gallery.map((page, key) => (
                                <img key={key} src={page.featuredImage} alt="" />
                            ))}
                        </HTMLFlipBook>
                        <SectionAds />
                    </div>
                </>
            )}
        </>
    )
}