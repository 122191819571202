import Image from "../Image";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const SectionAds = ({
    className = "",
    imgAds,
    link,
}) => {
    let google = false;

    if (imgAds === "https://avrupaninsesi.com/isDosyalar/null/null" || imgAds === undefined) {
        google = true;
    }

    useEffect(() => {
        if (google === true) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (error) {
                console.error("An error occurred while loading Google Ads:", error);
            }
        }
    }, [google])

    return (
        <>
            {!google && (
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={link}
                    className={`nc-SectionAds block text-center mx-auto ${className}`}
                >
                    <Image className="mx-auto rounded-xl" src={imgAds} alt="ads" />
                </Link>
            )}
            {google && (
                <>
                    <ins className='adsbygoogle'
                        style={{ display: 'block' }}
                        data-ad-client='ca-pub-8151329584756198'
                        data-ad-slot='8605736181'
                        data-ad-format='auto'
                        responsive='true'
                    />
                </>
            )}
        </>
    );
};

export default SectionAds;
