import { useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function GoBackButton({ background }) {
    const [isVisible, setIsVisible] = useState(false);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
        window.scrollTo(0, 0);
    };

    return (
        <div className={`flex w-1/2 items-center h-10 md:rounded-b-none  text-white  ${background !== false && ' bg-neutral-900'}`}>
            <button
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                onClick={handleGoBack}
                className='bg-[#ec1f27] px-2.5 flex justify-center gap-x-2.5 items-center rounded-full fixed z-[999]'
            >
                <MdOutlineArrowBack size={24} />
                {isVisible && <span>Geri Git</span>}
            </button>
        </div>

    )
}