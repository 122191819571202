import React, { useEffect, useRef, useState } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import SingleTitle from "../../app/(singles)/SingleTitle";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import { toast } from "react-hot-toast";
import { FiShare2 } from "react-icons/fi";
import { BsCopy } from "react-icons/bs";
import { FaFacebook, FaLinkedin, FaTelegram, FaXTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import PostCardLikeAndComment from "../../components/PostCardLikeAndComment/PostCardLikeAndComment";

const AuthorHeader = ({ titleMainClass, className = "", details = [], totalComment = 0, author = [] }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const dialogRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                setOpenDialog(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dialogRef]);

    const sharedSocial = (socialMedia, text) => {
        const url = window.location.href;
        switch (socialMedia) {
            case "copy":
                const el = document.createElement('textarea');
                el.value = window.location.href;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                toast.success("Link kopyalandı")
                setOpenDialog(false)
                break;

            case "facebook":
                const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
                window.open(facebookURL, "_blank");
                break;

            case "twitter":
                const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
                window.open(twitterURL, "_blank");
                break;

            case "whatsapp":
                const whatsappURL = `whatsapp://send?text=${encodeURIComponent(text + "\n" + url)}`;
                window.open(whatsappURL, "_blank");
                break;

            case "telegram":
                const telegramURL = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
                window.open(telegramURL, "_blank");
                break;

            case "linkedin":
                const linkedinURL = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`;
                window.open(linkedinURL, "_blank");
                break;

            default:
                break;
        }
        setOpenDialog(false);
    }

    return (
        <>
            <div className={`nc-SingleHeader ${className}`}>
                <div className="space-y-5">
                    <CategoryBadgeList
                        itemClass="!px-3"
                        categories={[{
                            "name": details.category,
                            "color": "indigo",
                            "taxonomy": "category",
                        }]}
                    />

                    <SingleTitle
                        mainClass={titleMainClass}
                        title={details.title}
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div
                        className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                        <PostMeta2
                            meta={
                                {
                                    date: "21/21/21",
                                    author: {
                                        displayName: author.displayName,
                                        avatar: author.avatar,
                                        href: '/yazarlar/detay/' + author.href,
                                    }
                                }
                            }
                            size="large"
                            className="leading-none flex-shrink-0"
                            hiddenCategories
                            avatarRounded="rounded-full shadow-inner"
                        />
                        <div className="flex gap-x-2.5 relative">
                            <PostCardLikeAndComment
                                likeCount={details.hit}
                                commentCount={totalComment}
                                itemClass="px-4 h-9 text-sm"
                                hiddenCommentOnMobile
                                useOnSinglePage
                                className="!space-x-2.5"
                            />
                            <div
                                onClick={() => setOpenDialog(!openDialog)}
                                className="min-w-[68px] h-9 px-4 flex items-center justify-center gap-x-2.5 cursor-pointer text-sm rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-blue-50 dark:hover:bg-blue-100 hover:text-teal-600 dark:hover:text-blue-500">
                                <FiShare2 size={18} />
                                Paylaş
                            </div>
                            <div
                                ref={dialogRef}
                                className={`absolute bg-white text-zinc-500 rounded-2xl shadow-lg grid grid-cols-12 top-10 right-0 md:p-10 p-5 gap-y-3 z-50 ${openDialog ? 'block' : 'hidden'}`}>
                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-zinc-500 transition-colors"
                                    onClick={() => sharedSocial("copy", details.title)}>
                                    <BsCopy className="text-zinc-500" size={16} /> Linki Kopyala
                                </p>

                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-500 transition-colors"
                                    onClick={() => sharedSocial("facebook", details.title)}>
                                    <FaFacebook className="text-blue-500" size={16} /> Facebook'ta Paylaş
                                </p>

                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-zinc-800 transition-colors"
                                    onClick={() => sharedSocial("twitter", details.title)}>
                                    <FaXTwitter className="text-zinc-800" size={16} /> Twitter'da Paylaş
                                </p>

                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-green-500 transition-colors"
                                    onClick={() => sharedSocial("whatsapp", details.title)}>
                                    <FaWhatsapp className="text-green-500" size={16} /> Whatsapp'ta Paylaş
                                </p>

                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-400 transition-colors"
                                    onClick={() => sharedSocial("telegram", details.title)}>
                                    <FaTelegram className="text-blue-400" size={16} /> Telegram'da Paylaş
                                </p>

                                <p className="col-span-12 flex items-center gap-x-2.5 cursor-pointer hover:text-blue-700 transition-colors"
                                    onClick={() => sharedSocial("linkedin", details.title)}>
                                    <FaLinkedin className="text-blue-700" size={16} /> Linkedin'de Paylaş
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthorHeader;
