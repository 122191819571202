import {combineReducers} from "@reduxjs/toolkit";
import authReducer from "./auth.js";
import modalReducer from "./modal.js";
import userReducer from "./user.js"
import settingsReducer from './settings';
import {SettingsState} from "./settings";

export interface RootState {
    settings: SettingsState;
}

const rootReducer = combineReducers({
    // reducer'lar buraya eklenecek
    auth: authReducer,
    modal: modalReducer,
    user: userReducer,
    settings: settingsReducer
})

export default rootReducer