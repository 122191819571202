import React, { CSSProperties, FC } from "react";
import LazyLoad from "react-lazyload";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    fill?: boolean;
    style?: CSSProperties;
    onLoadingComplete?: () => void;
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

const Image: FC<Props> = ({
    fill = false,
    className,
    alt = "",
    style,
    onLoadingComplete,
    onClick,
    ...args
}) => {
    return (
        <LazyLoad height={200} offset={100} once>
            <img
                onClick={onClick}
                {...args}
                className={
                    className + (fill ? " object-cover absolute inset-0 w-full h-full" : "")
                }
                alt={alt}
                width={'100%'}
                style={style}
                onLoad={onLoadingComplete}
            />
        </LazyLoad>
    );
};

export default Image;
