import React, { FC } from "react";
import { Link } from "react-router-dom";
import { TbClockPlus } from "react-icons/tb";
import { formatDateTime } from "utils/formatDateTime";

export interface PostCardCommentBtnProps {
    className?: string;
    isATagOnSingle?: boolean;
    commentCount?: string;
    href?: string;
}

const PostCardCommentBtn: FC<PostCardCommentBtnProps> = ({
    className = "flex px-3 h-8 text-xs",
    isATagOnSingle = false,
    commentCount = "",
    href
}) => {
    if (isATagOnSingle) {
        return (
            <a
                href={"#comments"}
                className={`nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 ${className} `}
                title="Comments"
            >
                <TbClockPlus size={24} />

                <span className="ml-1 text-neutral-900 dark:text-neutral-200">
                    {formatDateTime(commentCount, "y/m/d")}
                </span>
            </a>
        );
    }

    return (
        <Link
            to={href || "#"}
            className={`nc-PostCardCommentBtn relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 ${className} `}
            title="Comments"
        >
            <TbClockPlus size={24} />

            <span className="ml-1 text-neutral-900 dark:text-neutral-200">
                {formatDateTime(commentCount, "y/m/d")}
            </span>
        </Link>
    );
};

export default PostCardCommentBtn;
