import { useEffect } from "react";
import Header from "components/Header/Header";
import { useLocation } from "react-router-dom";

const SiteHeader = () => {
  let pathname = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return <Header />;
};
export default SiteHeader;
