import {FC} from "react";
import Avatar from "components/Avatar/Avatar";

export interface CommentType {
    id: number;
    date: string;
    content: string;
    like?: {
        count: number;
        isLiked?: boolean;
    };
    displayName: string
}

export interface CommentCardProps {
    className?: string;
    comment?: CommentType;
    size?: "large" | "normal";
}

const CommentCard: FC<CommentCardProps> = ({className = "", comment = {}, size = "large"}) => {
    const {date, content, displayName} = comment;

    return (
        <>
            <div className={`nc-CommentCard flex ${className}`}>
                <Avatar
                    sizeClass={`h-6 w-6 text-base ${size === "large" ? "sm:text-lg sm:h-8 sm:w-8" : ""}`}
                    radius="rounded-full"
                    containerClassName="mt-4"
                    imgUrl="/user.png"

                />
                <div
                    className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
                    <div className="relative flex items-center pr-6">
                        <span className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100">
                            {displayName}
                        </span>
                        <span className="mx-2">·</span>
                        <span
                            className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">{date}</span>
                    </div>
                    <span
                        className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">{content}</span>
                </div>
            </div>
        </>
    );
};

export default CommentCard;
