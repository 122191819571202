import {ErrorMessage, useField} from "formik";
import classNames from "classnames";

export default function Input({label, value, className, underlineStyle, ...props}) {

    const [field, meta] = useField(props);

    return (
        <label className="w-full font-light ">
            <div>{label}</div>
            <input {...field} {...props}
                   className={classNames({
                       "block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-full": !className && !underlineStyle,
                       "focus:border-primary": (!meta.error || !meta.touched) && !underlineStyle,
                       "border-red-600 focus:outline-primary": meta.error && meta.touched && !underlineStyle,
                       [className]: className,
                   })}/>
            <ErrorMessage name={field.name} component="small" className="text-red-500 text-sm uppercase"/>
        </label>
    )
}