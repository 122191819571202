// import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import {useField} from "formik";

export default function Textarea({ label, className, ...props }) {

    const [field] = useField(props);

    return (
        <label className="w-full font-light">
            <div>{label}</div>
            <textarea {...field} {...props}
                      className={classNames({
                          "block w-full text-sm rounded-xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900": !className,
                          [className]: className
                      })}></textarea>
            {/*<ErrorMessage name={field.name} component="small" className="text-red-500 text-sm uppercase" />*/}
        </label>
    )
}