import Card11 from "components/Card11/Card11";
import Image from "components/Image";
import { useParams } from "react-router-dom";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { useEffect, useState } from "react";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import SectionAds from "components/Sections/SectionAds";

const Modules = () => {
    let params = useParams();
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState({ cover: "", totalNews: undefined });
    const [news, setNews] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(50);
    const [oldSlug, setOldSlug] = useState(null);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        async function fetchData() {
            if (oldSlug !== params.slug) {
                setStart(0);
                setEnd(50);
            }
            setOldSlug(params.slug);
            try {
                const data = await fetchAPI('moduleCategories', createFormData, sendRequest, start, end, params.slug);
                const categoryDetails = data.details[0];
                const categoryNews = data.news;

                categoryNews.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                });

                setDetails(categoryDetails);
                setNews(categoryNews);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, params.slug, start, end, oldSlug]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className={`nc-PageArchive`}>
                    <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
                        <div
                            className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
                            <Image
                                alt="archive"
                                fill
                                src={details.cover}
                                className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
                                sizes="(max-width: 1280px) 100vw, 1536px"
                            />
                            <div
                                className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                                <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                                    {details.title}
                                </h2>
                                <span className="block mt-4 text-neutral-300">{details.totalNews} Haber</span>
                            </div>
                        </div>
                    </div>
                    <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                        <SectionAds />
                        <div>
                            <div
                                className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                                {news.map((post) => (
                                    <Card11 key={post.id} post={post} />
                                ))}
                            </div>
                            <div className="flex justify-end text-sm mt-3">
                                {news.length} adet kayıt listelendi
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modules;
