import React, { FC } from "react";
// import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";

export interface PostCardLikeAndCommentProps {
    className?: string;
    itemClass?: string;
    hiddenCommentOnMobile?: boolean;
    useOnSinglePage?: boolean;
    likeCount?: number;
    commentCount?: string;
    href?: string;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
    className = "",
    itemClass = "px-3 h-8 text-xs",
    hiddenCommentOnMobile = true,
    useOnSinglePage = false,
    likeCount = 0,
    commentCount = "",
    href
}) => {
    return (
        <div
            className={`nc-PostCardLikeAndComment flex items-center space-x-2 mt-4 ${className}`}
        >
            <PostCardLikeAction likeCount={likeCount} className={itemClass} />
            {/* <PostCardCommentBtn
                commentCount={commentCount}
                href={href}
                className={`${hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
                    }  ${itemClass}`}
                isATagOnSingle={useOnSinglePage}
            /> */}
        </div>
    );
};

export default PostCardLikeAndComment;
