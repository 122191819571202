import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import Navigation from "components/Navigation/Navigation";
import SearchModal from "./SearchModal";
import NavbarInfo from "../NavbarInfo";

const MainNav = () => {
    const renderContent = () => {
        return (
            <div className="h-20 flex justify-between">
                <div className="flex items-center lg:hidden flex-1">
                    <MenuBar />
                </div>

                <div className="lg:flex-1 flex items-center">
                    <Logo />
                </div>

                <div className="flex-1 hidden lg:flex items-center justify-end text-slate-700 dark:text-slate-100">
                    <Navigation />
                    <SearchModal />
                </div>
            </div>
        );
    };

    return (
        <div
            className="nc-MainNav2Logged flex justify-between items-center relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
            <div className="container">
                {renderContent()}
            </div>
            <div className="hidden lg:flex justify-center items-center gap-x-1.5 ">
                <NavbarInfo />
            </div>
        </div>
    );
};

export default MainNav;
