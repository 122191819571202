import {createSlice} from "@reduxjs/toolkit";

const auth = createSlice({
    name: "auth",
    initialState: null,
    reducers: {
        setToken: (state, action) => {
            return action.payload
        }
    }
})

export const {setToken} = auth.actions;
export default auth.reducer;