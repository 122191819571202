import Card11 from "components/Card11/Card11";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Image from "components/Image";
import { useParams } from "react-router-dom";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import { FaSpinner } from "react-icons/fa6";
import SectionAds from "components/Sections/SectionAds";
import GoBackButton from "components/GoBackButton";

const Article = () => {
    let params = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({ cover: "", totalNews: undefined });
    const [news, setNews] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(50);
    const [continueButton, setContinueButton] = useState("Devamını Gör");
    const [oldSlug, setOldSlug] = useState(null);
    const [order, setOrder] = useState("newest");
    const [others, setOthers] = useState([]);
    const [ads, setAds] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        async function fetchData() {
            if (oldSlug !== params.slug) {
                setStart(0);
                setEnd(50);
                setLoading(true);
            }
            setOldSlug(params.slug);

            try {
                const data = await fetchAPI('categoryDetails', createFormData, sendRequest, start, end, params.slug, order);
                const categoryDetails = data.details[0];
                const categoryNews = data.news;
                const otherCategories = data.otherCategories;

                categoryNews.forEach(item => {
                    item.featuredImage = 'https://avrupaninsesi.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                });

                otherCategories.forEach(item => {
                    item.href = '/kategori/' + item.href;
                });

                const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 48);
                adsData.forEach(item => {
                    item.imgAds = 'https://avrupaninsesi.com/isDosyalar/' + item.yol + "/" + item.url;
                });
                setAds(adsData);

                setDetails(categoryDetails);
                setNews(categoryNews);
                setOthers(otherCategories);
                setLoading(false);
                setContinueButton("Devamını Gör")

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
                setContinueButton("Devamını Gör")
            }
        }

        fetchData();
    }, [createFormData, sendRequest, params.slug, start, end, oldSlug, order]);

    const handlePageChange = () => {
        setContinueButton(<FaSpinner className="animate-spin" />)
        setStart(end);
        setEnd(end + 50);
    };

    const orderNews = e => {
        const currentOrder = e.target.value;
        setOrder(currentOrder);
    }

    const randomAdIndex = Math.floor(Math.random() * ads.length);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className={`nc-PageArchive`}>
                    <GoBackButton background={false} />
                    <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
                        <div
                            className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
                            <Image
                                alt="archive"
                                fill
                                src={details.cover || '/default-cover.jpg'}
                                className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
                                sizes="(max-width: 1280px) 100vw, 1536px"
                                width={720}
                            />
                            <div
                                className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                                <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                                    {details.title}
                                </h2>
                                <span className="block mt-4 text-neutral-300">{details.totalNews} Haber</span>
                            </div>
                        </div>
                    </div>

                    <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                        {ads[randomAdIndex] && (
                            <SectionAds
                                link={ads[randomAdIndex].link}
                                imgAds={ads[randomAdIndex].imgAds}
                                title={ads[randomAdIndex].baslik}
                            />
                        )}

                        <div>
                            <div className="flex flex-col sm:justify-between sm:flex-row">
                                <div className="flex w-full justify-end">
                                    <select id="order" className="rounded-full border-0 shadow"
                                        onChange={orderNews}>
                                        <option selected={order === "newest"} value="newest">En Yeni</option>
                                        <option selected={order === "oldest"} value="oldest">En Eski</option>
                                        <option selected={order === "mostViewd"} value="mostViewd">En Çok Görüntülenen
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div
                                className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                                {news.map((post) => (
                                    <Card11 key={post.id} post={post} />
                                ))}
                            </div>
                            <div className="flex justify-end text-sm mt-3">
                                {news.length} adet kayıt listelendi
                            </div>
                            <button
                                type="button"
                                disabled={news.length >= details.totalNews}
                                className="flex justify-center items-center mt-12 border w-64 mx-auto p-3 rounded-full bg-[#b91c1c] text-white cursor-pointer disabled:bg-gray-600 disabled:text-gray-400 disabled:cursor-not-allowed"
                                onClick={handlePageChange}>
                                {news.length >= details.totalNews ? "Tümü yüklendi" : continueButton}
                            </button>
                        </div>

                        <SectionSliderNewAuthors
                            heading=""
                            subHeading=""
                            authors={others}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Article;
