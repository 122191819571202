import React, { useEffect, useState } from "react";
import Image from "../../components/Image";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import CardAuthorBox from "../../components/CardAuthorBox/CardAuthorBox";

export default function Authors() {
    const [loading, setLoading] = useState(false);
    const [authors, setAuthors] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        setLoading(loading);

        async function fetchData() {
            try {
                const authorsData = await fetchAPI('authorsDetails', createFormData, sendRequest);
                authorsData.forEach((item: {
                    href: string;
                    seo_link: string;
                    avatar: any;
                }) => {
                    item.avatar = "https://www.avrupaninsesi.com/" + item.avatar;
                    item.href = "detay/" + item.seo_link;
                })
                setAuthors(authorsData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, loading]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <div className="grid grid-cols-12 gap-5 container">
                    <div className="col-span-12">
                        <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
                            <div
                                className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
                                <Image
                                    alt="archive"
                                    fill
                                    src="/yazarlar.jpg"
                                    className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
                                    sizes="(max-width: 1280px) 100vw, 1536px"
                                />
                                <div
                                    className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                                    <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                                        Yazarlar
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    {authors && (
                        <>
                            {authors.map((author, key) => (
                                <div key={key} className="col-span-6 lg:col-span-2 md:col-span-3">
                                    {/*<AuthorCard content={author}/>*/}
                                    <CardAuthorBox key={key} author={author} />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}
        </>
    )
}